<script setup lang="ts">
import type GoogleSheetsConfirmModal from '#core/components/container/GoogleSheetsConfirmModal.vue'
import { useNavState } from '#core/utils/nav'
import { isCurrent } from '#imports'
import { mergeConfig } from '#ui/utils'
import { TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  createReusableTemplate,
} from '@vueuse/core'
import { computed } from 'vue'

// @ts-expect-error untyped
import appConfig from '#build/app.config'
import { slideover } from '#ui/ui.config'

defineOptions({
  inheritAttrs: false,
})
const tracer = useTracer()

const { slideoverMenu: modelValue, expanded } = useNavState()

const config = mergeConfig(appConfig.ui.strategy, appConfig.ui.slideover, slideover)
const { ui, attrs } = useUI('slideover', computed(() => ({
  wrapper: `transform ${expanded.value ? 'translate-x-[260px]' : 'translate-x-[52px]'} transition-all`,
  width: 'max-w-[280px]',
  overlay: {
    background: 'bg-gray-900/10',
  },
})), config, ref('')) as Record<string, Ref<Record<string, any>>>

const transitionClass = computed(() => {
  const enterFrom = ui.value.translate.left
  const leaveTo = ui.value.translate.left
  return {
    ...ui.value.transition,
    enterFrom,
    enterTo: ui.value.translate.base,
    leaveFrom: ui.value.translate.base,
    leaveTo,
  }
})

const googleSheetsConfirmModal = ref<InstanceType<typeof GoogleSheetsConfirmModal> | null>()

const [DefineNavList, NavList] = createReusableTemplate<{ items: { to: string, label: string, icon?: any }[] }>()
const [DefineNavTitle, NavTitle] = createReusableTemplate<{ label: string, to?: string, icon?: string }>()

async function handleLinkClick(to: string) {
  tracer.custom('nav_link_click', { to })
  if (to.startsWith('https://docs.google.com/spreadsheets')) {
    if (await googleSheetsConfirmModal.value?.confirm()) {
      window.open(to, '_blank')
    }
    return
  }
  to.startsWith('http') ? window.open(to, '_blank') : navigateTo(to)
}
</script>

<template>
  <DefineNavList v-slot="{ items }">
    <ul>
      <li v-for="(child, childKey) in items" :key="childKey">
        <ULink :to="child.to" class="flex items-center justify-between w-full py-2 px-3 text-forgd-gray-600 hover:bg-forgd-neutral-400 transition rounded" @click.prevent="handleLinkClick(child.to)">
          <div>{{ child.label }}</div>
          <UIcon v-if="child.to?.startsWith('http')" name="i-heroicons-arrow-up-right" />
          <svg v-else-if="isCurrent(child, true)" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" class="ml-1">
            <circle cx="4" cy="4" r="4" fill="#2D73FF" />
          </svg>
        </ULink>
      </li>
    </ul>
  </DefineNavList>
  <DefineNavTitle v-slot="{ label, icon }">
    <div class="flex items-center text-forgd-primary-900 gap-2 mb-2">
      <UIcon v-if="typeof icon === 'string'" :name="icon" class="w-5 h-5 text--bgd-200" />
      <div class="font-semibold">
        {{ label }}
      </div>
    </div>
  </DefineNavTitle>
  <TransitionRoot as="template" appear :show="modelValue !== null">
    <div :class="[ui.wrapper]" v-bind="attrs">
      <TransitionChild as="template" appear v-bind="transitionClass">
        <div data-el="AppMenuSlideover" class="bg-white h-full border-r border-forgd-gray-300" :class="[ui.base, ui.width, ui.background, ui.ring, ui.padding]">
          <TransitionGroup name="slideovers-animation" tag="div">
            <div :key="String(modelValue)" class="font-display text-xs relative max-h-screen overflow-y-auto light-scrollbar z-10">
              <slot :model-value="modelValue" :nav-title="NavTitle" :nav-list="NavList" />
            </div>
          </TransitionGroup>
        </div>
      </TransitionChild>
    </div>
  </TransitionRoot>
  <GoogleSheetsConfirmModal ref="googleSheetsConfirmModal" />
</template>

<style>
.slideovers-animation-move,
.slideovers-animation-enter-active,
.slideovers-animation-leave-active {
  transition: all 0.3s ease;
  overflow: hidden;
}
.slideovers-animation-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.slideovers-animation-enter-from {
  opacity: 0;
  transform: translateX(30px);
}
.slideovers-animation-leave-active {
  position: absolute;
}
</style>
